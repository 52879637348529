import React, { useEffect, useState } from "react";
import "./less/index.less";
import Nav3 from "../Home/Nav3";
import Footer1 from "../Home/Footer1";
import { Link, useHistory } from "react-router-dom";
import { Nav30DataSource, Footer10DataSource } from "../Home/data.source";
import { Tabs, Row, Col, Card, message } from "antd";
import serviceAxios from "../config/axios";
import { useLocation } from "react-router-dom";

const { TabPane } = Tabs;
function parse(str = window.location.search) {
  const reg = /([^?&=]+)=([^&]+)/g; // 分组捕获 两个子组
  const params = {};
  str.replace(reg, (_, k, v) => (params[k] = v));
  return params;
}

const Category = (props) => {
  const index = parse(window.location.href).index;
  const [tabIndex, setTableIndex] = useState(index || 0);
  const [config, setConfig] = useState([]);
  const [titles, setTitles] = useState([]);

  const [subTypeId, setSubTypeId] = useState();

  const [data, setData] = useState([]);

  const [user, setUser] = useState();

  const history = useHistory();

  const [activeDataIndex, setActiveDataIndex] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    serviceAxios
      .get(
        `/api/subject/subjectType/list?pageNum=1&pageSize=100000&txType=${
          Number(tabIndex) + 1
        }`
      )
      .then((res) => {
        if (res) {
          setConfig(res.data.items);
          if (res.data.items.length) {
            setSubTypeId(res.data.items[0].id);
          }
        }
      });
    serviceAxios
      .get("/api/manage/homeType/pc/list?pageNum=1&pageSize=100")
      .then((res) => {
        if (res && res.data) {
          console.log(res);
          setTitles(res.data.items);
        }
      });
  }, [tabIndex]);

  useEffect(() => {
    if (subTypeId) {
      serviceAxios
        .get(
          `/api/subject/v2/subject/list?pageNum=1&pageSize=100000&schoolId=-1&subTypeId=${subTypeId}`
        )
        .then((res) => {
          if (res) {
            setData(res.data.items);
            setActiveDataIndex(0);
          }
        });
    }
  }, [subTypeId]);

  useEffect(() => {
    serviceAxios.get("/api/user/user/info").then((res) => {
      if (res.code == 601) {
        message.warn(res.msg);
      } else {
        setUser(res.data);
      }
    });
  }, []);

  const mainVideo = config.filter((i) => i.type === 0);
  const secondVideo = config.filter((i) => i.type === 1);

  const cards = data[activeDataIndex] ? data[activeDataIndex].items : [];

  const subTypeName = config.filter((i) => i.id === subTypeId)[0]
    ? config.filter((i) => i.id === subTypeId)[0].name
    : "";

  console.log(tabIndex);
  return (
    <div className="category-page">
      <Nav3
        id="Nav3_0"
        key="Nav3_0"
        dataSource={Nav30DataSource}
        isMobile={false}
      />

      <div className="home-page-wrapper">
        <div
          className="home-page"
          style={{ padding: "40px 24px", cursor: "pointer" }}
          onClick={() => {
            history.goBack();
          }}
        >{`< 返回`}</div>
        <Tabs
          type="card"
          className="home-page"
          style={{ paddingBottom: 20, paddingTop: 0 }}
          tabIndex={tabIndex}
          activeKey={tabIndex.toString()}
          onChange={(active) => setTableIndex(active)}
        >
          {titles.map((i, index) => (
            <TabPane tab={i.name} key={index} className="content">
              <div className="wrap">
                课程教学视频：
                <div className="items">
                  {mainVideo.map((i, _index) => (
                    <span
                      key={_index}
                      onClick={() => setSubTypeId(i.id)}
                      className={subTypeId === i.id ? "active" : ""}
                    >
                      {i.name}
                    </span>
                  ))}
                </div>
              </div>
              <div className="wrap">
                辅助教学视频：
                <div className="items">
                  {secondVideo.map((i, _index) => (
                    <span
                      key={_index}
                      onClick={() => setSubTypeId(i.id)}
                      className={subTypeId === i.id ? "active" : ""}
                    >
                      {i.name}
                    </span>
                  ))}
                </div>
              </div>
            </TabPane>
          ))}
        </Tabs>
        <div className="home-page" style={{ paddingTop: 0, minHeight: "40vh" }}>
          <h1>选择学习阶段</h1>
          <div className="tt">
            {data.map((i, index) => (
              <div
                key={index}
                className={activeDataIndex === index ? "active-data" : ""}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setActiveDataIndex(index)}
              >
                {i.name}
              </div>
            ))}
          </div>
          <Row gutter={[16, 16]}>
            {cards.map((j, _index) => (
              <Col span={6} key={_index}>
                <Link
                  onClick={(e) => {
                    if (!user || user.type !== 2) {
                      message.warn("您没有观看权限");
                      e.preventDefault();
                      return;
                    }
                  }}
                  to={{
                    pathname: `/class/${j.id}`,
                    state: {
                      breads: [
                        titles[tabIndex],
                        subTypeName,
                        data[activeDataIndex].name,
                        j.name,
                      ],
                    },
                  }}
                >
                  <Card
                    hoverable
                    type="inner"
                    style={{ width: 240 }}
                    cover={<img alt="example" src={j.coverImage} />}
                  >
                    {j.name}
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={false}
      />
    </div>
  );
};

export default Category;
