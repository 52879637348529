import React, { useEffect, useState } from "react";
import "./less/index.less";
import Nav3 from "../Home/Nav3";
import Footer1 from "../Home/Footer1";
import { Nav30DataSource, Footer10DataSource } from "../Home/data.source";
import serviceAxios from "../config/axios";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Breadcrumb, Col, Space, Table, Row } from "antd";
export const ILessonConfigData = [
  {
    period: '课堂导入',
    data: [
      {
        title: '宝贝启蒙 彩虹变变变',
        id: 2,
        status: 1,
        statusName: '观看'
      },
      {
        title: '彩虹糖变变变',
        id: 2,
        status: 2,
        statusName: '下载'
      }
    ]
  }, {
    period: '教学引导',
    data: [
      {
        title: '宝贝启蒙 彩虹变变变',
        id: 2,
        status: 1,
        statusName: '观看'
      },
      {
        title: '彩虹糖变变变',
        id: 2,
        status: 2,
        statusName: '下载'
      }
    ]
  }, {
    period: '启发创作',
    data: [
      {
        title: '宝贝启蒙 彩虹变变变',
        id: 2,
        status: 1,
        statusName: '观看'
      },
      {
        title: '彩虹糖变变变',
        id: 2,
        status: 2,
        statusName: '下载'
      }
    ]
  }, {
    period: '课堂总结',
    data: [
      {
        title: '宝贝启蒙 彩虹变变变',
        id: 2,
        status: 1,
        statusName: '观看'
      },
      {
        title: '彩虹糖变变变',
        id: 2,
        status: 2,
        statusName: '下载'
      }
    ]
  },
]


const ClassPage = () => {
  const { id } = useParams();

  const { state } = useLocation();

  const [files, setFiles] = useState([]);

  const [videos, setVideos] = useState([]);

  const [current, setCurrent] = useState(0);

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    serviceAxios
      .get(
        `/api/subject/courseware/list?pageNum=1&pageSize=100000&type=1&subjectId=${id}`
      )
      .then((res) => {
        if (res) {
          setFiles(
            res.data.files.map((i, index) => ({
              ...i,
              index: index + 1,
            }))
          );
          setVideos(res.data.videos.items);
        }
      });
  }, [id]);

  useState(() => {
    if (state) {
      localStorage.setItem("breads", state.breads);
    }
  }, [state]);

  const columns = [
    {
      title: "序号",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "资料名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      render: (_, { type }) => (
        <>
          <div>{type === 0 ? "视频" : "文件"}</div>
        </>
      ),
    },
    {
      title: "下载",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a href={record.url} target="_blank">
            观看
          </a>
        </Space>
      ),
    },
  ];

  const breads = localStorage.getItem("breads")
    ? localStorage.getItem("breads").split(",")
    : state;

  return (
    <div className="category-page">
      <Nav3
        id="Nav3_0"
        key="Nav3_0"
        dataSource={Nav30DataSource}
        isMobile={false}
      />
      <div className="home-page-wrapper">
        <div className="home-page">
          <Breadcrumb>
            <Breadcrumb.Item onClick={goBack}>{breads[0]}</Breadcrumb.Item>
            <Breadcrumb.Item  onClick={goBack}>{breads[1]}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={goBack}>{breads[2]}</Breadcrumb.Item>
            <Breadcrumb.Item>{breads[3]}</Breadcrumb.Item>
          </Breadcrumb>
          <Row style={{ marginTop: 20 }}>
            <Col span={18}>
              {videos && videos.length ? (
                <video
                  controls
                  src={videos[current].file.url}
                  style={{ width: "100%" }}
                  autoPlay={false}
                ></video>
              ) : (
                ""
              )}
            </Col>
            <Col span={6} style={{ background: "#999999" }}>
              <div className="item-v">目录</div>
              {videos.map((i, index) => (
                <div
                  className={
                    current === index ? "item-v item-v-active" : "item-v"
                  }
                  onClick={() => setCurrent(index)}
                >
                  {/* {`第${index + 1}阶段`} */}
                  {ILessonConfigData[index].period}
                </div>
              ))}
            </Col>
          </Row>
          <h1 style={{ margin: "20px 0" }}>教学课件</h1>
          <Table columns={columns} dataSource={files} pagination={false} />
        </div>
      </div>
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={false}
      />
    </div>
  );
};

export default ClassPage;
