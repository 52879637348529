import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from "enquire-js";
import Home from "./Home";
import LoginPage from "./Login";
import Category from "./Category";
import ClassPage from "./Class";
import AuthPage from './Auth/index';
import './App.css'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/category" component={Category} />
          <Route exact path="/auth" component={AuthPage} />
          <Route exact path="/class/:id" component={ClassPage} />
        </div>
      </Router>
    );
  }
}
export default App;


