import React, { useEffect } from "react";
import "./less/index.less";
const AuthPage = () => {
  useEffect(() => {
    var obj = new window.WxLogin({
      self_redirect: true,
      id: "login_container",
      appid: "wxf301e96d257c51ef",
      scope: "snsapi_login",
      // redirect_uri: "https%3A%2F%2Fapi.fanqiushuhua.com%2Fapi%2Fuser%2Fuser%2FwebLogin",
      redirect_uri: "http%3A%2F%2Fwww.fanqiushuhua.com%2F%23%2F",
      state: "STATE",
      style: "black",
      href: "",
    });
  }, []);
  return (
    <div className="login-page" style={{background:'black'}}>
      <div id="login_container"></div>
    </div>
  );
};

export default AuthPage;
