import React from "react";
import { DownOutlined } from "@ant-design/icons";
import TweenOne, { TweenOneGroup } from "rc-tween-one";
import BannerAnim, { Element } from "rc-banner-anim";
import "rc-banner-anim/assets/index.css";
import serviceAxios from "../config/axios";
import img1 from "../assets/1.png";
import img2 from "../assets/2.png";
import img3 from "../assets/3.png";
import img4 from "../assets/4.png";
import { Carousel, Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import BannerJpg from "../assets/banner.jpg";
import T1 from "../assets/t1.jpg";
import T2 from "../assets/t2.jpg";
import T3 from "../assets/t3.jpg";
import T4 from "../assets/t4.jpg";
import T5 from "../assets/t5.jpg";
import T6 from "../assets/t6.jpg";
import T7 from "../assets/t7.jpg";
import T8 from "../assets/t8.jpg";
import Feature7 from "./Feature7";
import A1 from "../assets/a1.jpg";
import A2 from "../assets/a2.jpg";
import A3 from "../assets/a3.jpg";
import A4 from "../assets/a4.jpg";
import A5 from "../assets/a5.jpg";
import A6 from "../assets/a6.jpg";
import { Feature70DataSource } from "./data.source";
const { BgElement } = Element;

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [BannerJpg],
      ads: [
        {
          img: A1,
        },
        {
          img: A2,
        },
        {
          img: A3,
        },
        {
          img: A4,
        },
        {
          img: A5,
        },
        {
          img: A6,
        },
      ],
      teachers: [
        {
          img: T1,
          title: "凡求书画教育机构创始人",
          name: "樊求",
        },
        {
          img: T2,
          title: "书法教研领头人",
          name: "王德营",
        },
        {
          img: T3,
          title: "凡求书画教学总监",
          name: "李名扬",
        },
        {
          img: T4,
          title: "书法教研组组长",
          name: "蒋大信",
        },
        {
          img: T5,
          title: "儿画教研组组长",
          name: "谢苗苗",
        },
        {
          img: T6,
          title: "素描教研组组长",
          name: "李明",
        },
        {
          img: T7,
          title: "资深书法老师",
          name: "杨阳",
        },
        {
          img: T8,
          title: "资深儿画老师",
          name: "顾萌君",
        },
      ],
      teachersText: [
        "凡求书画教育机构创始人",
        "书法教研领头人",
        "凡求书画教学总监",
        "书法教研组组长",
        "儿画教研组组长",
        "素描教研组组长",
        "资深书法老师",
        "资深儿画老师",
      ],
      menus: [],
    };
  }
  componentDidMount() {
    const that = this;
    serviceAxios
      .get("/api/manage/homeType/pc/list?pageNum=1&pageSize=100")
      .then((res) => {
        if (res && res.data) {
          console.log(res);
          that.setState({
            menus: res.data.items,
          });
        }
      });
  }

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    console.log(this.state.banner);
    return (
      <div {...props} {...dataSource.wrapper}>
        <div className="home-page" style={{ padding: 0, maxWidth: "100vw" }}>
          <TweenOneGroup
            key="bannerGroup"
            enter={{ opacity: 0, type: "from" }}
            leave={{ opacity: 0 }}
            component=""
          >
            <div className="banner1-wrapper" key="wrapper">
              {/* <BannerAnim key="BannerAnim" {...dataSource.BannerAnim}> */}
              <Carousel>
                {this.state.banner.map((i, index) => (
                  <div key={index} onClick={() => {}}>
                    <img src={i} key={index} style={{ width: "100%" }} />
                  </div>
                ))}
              </Carousel>
              {/* </BannerAnim> */}
              <div
                style={{
                  width: "80%",
                  margin: "30px auto",
                  textAlign: "center",
                  background: "white",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    fontSize: "2rem",
                    fontWight: "bold",
                    margin: "30px 0",
                  }}
                >
                  凡求书画类型
                </div>
                <Row gutter={16} justify="center">
                  {this.state.menus.map((i, index) => (
                    <Col
                      key={index}
                      span={3}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link to={`category?index=${index}&id=${i.id}`}>
                        <img
                          src={i.image}
                          alt=""
                          style={{ marginBottom: 20, width: "100%" }}
                        />
                        {/* <p style={{ color: "black" }}>{i.name}</p> */}
                      </Link>
                    </Col>
                  ))}
                </Row>
                <div
                  style={{
                    fontSize: "1.5rem",
                    fontWight: "bold",
                    margin: "30px 0",
                  }}
                >
                  优秀作品展示
                </div>
                <Feature7 dataSource={Feature70DataSource} />
              </div>
            </div>
          </TweenOneGroup>
        </div>
        <div className="home-page" style={{ padding: 0, maxWidth: "100vw" }}>
          <div className="banner1-wrapper" key="wrapper">
            <div
              style={{
                width: "80%",
                margin: "30px auto",
                textAlign: "center",
                background: "white",
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "2rem",
                  fontWight: "bold",
                  margin: "30px 0",
                }}
              >
                凡求师资
              </div>
              <Row gutter={16} wrap justify="center">
                {this.state.teachers.map((i, index) => (
                  <Col
                    key={index}
                    span={5}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={i.img}
                      alt=""
                      style={{ marginBottom: 20, width: "100%" }}
                    />
                    <div
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      {i.name}
                    </div>
                    <div
                      style={{
                        marginBottom: 30,
                      }}
                    >
                      {i.title}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
        <div className="home-page" style={{ padding: 0, maxWidth: "100vw" }}>
          <div className="banner1-wrapper" key="wrapper">
            <div
              style={{
                width: "80%",
                margin: "30px auto",
                textAlign: "center",
                background: "white",
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "2rem",
                  fontWight: "bold",
                  margin: "30px 0",
                }}
              >
                凡求书画优势
              </div>
              <Row gutter={0} wrap justify="center">
                {this.state.ads.map((i, index) => (
                  <Col
                    key={index}
                    span={8}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img src={i.img} alt="" style={{ width: "100%" }} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
        {/* <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner1-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
          <DownOutlined />
        </TweenOne> */}
      </div>
    );
  }
}

export default Banner;
