import React, { useState, useEffect } from "react";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { Row, Col, Card } from "antd";
import { getChildrenToRender } from "./utils";
import serviceAxios from "../config/axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

const { Meta } = Card;

function Feature7(props) {
  const { dataSource, isMobile, ...tagProps } = props;
  const { blockWrapper, titleWrapper } = dataSource;
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabContent, setTabContent] = useState([]);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    serviceAxios
      .get("/api/works/worksType/list?pageNum=1&pageSize=100")
      .then((res) => {
        if (res && res.code === 200) {
          setTabs(res.data.items);
        }
      });
  }, []);

  useEffect(() => {
    if (tabs && tabs.length) {
      serviceAxios
        .get(
          `/api/works/works/list?pageNum=1&pageSize=10000&worksTypeId=${tabs[activeIndex].id}`
        )
        .then((res) => {
          if (res) {
            console.log(res.data.items);
            setTabContent(res.data.items);
          }
        });
    }
  }, [activeIndex, tabs]);

  const childrenToRender = tabContent.map((i, index) => (
    <SwiperSlide key={index}>
      <img alt="example" src={i.image} style={{ width: 300, height: 300 }} />
    </SwiperSlide>
  ));
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        {/* <div {...dataSource.titleWrapper} style={{textAlign:'left'}}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div> */}
        {/* <Row gutter={[16, 16]}>
          {tabs.map((i, index) => (
            <Col
              span={3}
              key={index}
              onClick={() => setActiveIndex(index)}
              style={{
                // color: activeIndex === index ? "#fdeb9a" : "",
                color: activeIndex === index ? "#2196f3" : "",
                cursor: "pointer",
              }}
            >
              {i.name}
            </Col>
          ))}
        </Row> */}
        {/* <OverPack {...dataSource.OverPack}>
          <QueueAnim
            key="queue"
            type="bottom"
            leaveReverse
            interval={50}
            component={Row}
            {...blockWrapper}
          > */}
        <div
          // gutter={[16, 36]}
          style={{
            marginTop: 50,
            // columnCount: 4,
            // columnGap: 20,
            // height: "100vh",
            // display: "flex",
            // alignItems: "center",
            // overflow: "scroll",
          }}
        >
          {" "}
          <Swiper
            // spaceBetween={40}
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            loop={true}
            slidesPerView={3}
            speed={8000}
            // 注意app.css swiper container
            autoplay={{ delay: 0 }}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {childrenToRender}
          </Swiper>
        </div>
        {/* </QueueAnim>
        </OverPack> */}
      </div>
    </div>
  );
}

export default Feature7;
