import React from "react";
import LogoJpg from "../assets/logo.jpg";
export const Nav30DataSource = {
  wrapper: { className: "header3 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header3-logo",
    children: LogoJpg,
    // 'https://gw.alipayobjects.com/zos/basement_prod/b30cdc2a-d91c-4c78-be9c-7c63b308d4b3.svg',
  },
  Menu: {
    className: "header3-menu",
    children: [],
  },
  mobileMenu: { className: "header3-mobile-menu" },
};
export const Banner10DataSource = {
  wrapper: { className: "home-page-wrapper" },
  BannerAnim: {
    children: [
      {
        name: "elem0",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "banner1-text-wrapper" },
        bg: { className: "bg bg0" },
        title: {
          className: "banner1-title",
          children:
            "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
        },
        content: {
          className: "banner1-content",
          children: "一个高效的页面动画解决方案",
        },
        button: { className: "banner1-button", children: "Learn More" },
      },
      {
        name: "elem1",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "banner1-text-wrapper" },
        bg: { className: "bg bg1" },
        title: {
          className: "banner1-title",
          children:
            "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
        },
        content: {
          className: "banner1-content",
          children: "一个高效的页面动画解决方案",
        },
        button: { className: "banner1-button", children: "Learn More" },
      },
      {
        name: "elem2",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "banner1-text-wrapper" },
        bg: { className: "bg bg1" },
        title: {
          className: "banner1-title",
          children:
            "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
        },
        content: {
          className: "banner1-content",
          children: "一个高效的页面动画解决方案",
        },
        button: { className: "banner1-button", children: "Learn More" },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: "home-page-wrapper feature7-wrapper" },
  page: { className: "home-page feature7" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "feature7-title-wrapper",
    children: [
      {
        name: "title",
        className: "feature7-title-h1",
        children: "学生作品展示",
      },
    ],
  },
  blockWrapper: {
    className: "feature7-block-wrapper",
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: "block0",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block1",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block2",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block3",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block4",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block5",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block6",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block7",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: "身份证",
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: "识别身份证正反面姓名、身份证号、发证机关等相关信息",
            },
          ],
        },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: "home-page-wrapper content4-wrapper" },
  page: { className: "home-page content4" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      // {
      //   name: "title",
      //   children: "蚂蚁金融云提供专业的服务",
      //   className: "title-h1",
      // },
      // {
      //   name: "content",
      //   className: "title-content content4-title-content",
      //   children: "科技想象力，金融创造力",
      // },
    ],
  },
  video: {
    className: "content4-video",
    children: {
      video: "https://os.alipayobjects.com/rmsportal/EejaUGsyExkXyXr.mp4",
      image: "https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg",
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      // {
      //   name: "block0",
      //   xs: 24,
      //   md: 6,
      //   className: "block",
      //   title: {
      //     className: "logo",
      //     children:
      //       "https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg",
      //   },
      //   childWrapper: {
      //     className: "slogan",
      //     children: [
      //       {
      //         name: "content0",
      //         children: "Animation specification and components of Ant Design.",
      //       },
      //     ],
      //   },
      // },
      {
        name: "block1",
        xs: 24,
        md: 12,
        className: "block",
        title: { children: "" },
        childWrapper: {
          children: [
            { name: "link0", href: "https://beian.miit.gov.cn", children: "备案号：苏ICP备2021040733号-1" },
            { name: "link1", href: "#", children: "版权所有：扬州市凡求书画艺术培训中心有限公司" },
          ],
        },
      },
      {
        name: "block3",
        xs: 24,
        md: 12,
        className: "block",
        title: { children: "" },
        childWrapper: {
          children: [
            { href: "#", name: "link0", children: "加盟热线：400-828-0004" },
            // { href: "#", name: "link1", children: "客户服务热线：0514-8********" },
            { href: "#", name: "link1", children: "联系地址 : 扬州市邗江区邗江中路487号一楼凡求书画" },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        ©2018 by <a>凡求书画</a> All Rights
        Reserved
      </span>
    ),
  },
};
