import React from "react";
import TweenOne from "rc-tween-one";
import { getChildrenToRender } from "./utils";
import { Link } from "react-router-dom";
import serviceAxios from "../config/axios";
import { Avatar, Dropdown, Menu, Space } from "antd";
import { DownOutlined, SmileOutlined, UserOutlined } from "@ant-design/icons";
import Logo from "../assets/logo.png";
const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      user: undefined,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
    });
    // serviceAxios.get('/api/user/user/webLogin?code=061HYB0w3LhiRY2cfK1w3lc8M72HYB0&state=STATE')
    serviceAxios.get("/api/user/user/info").then((res) => {
      if (res) {
        localStorage.setItem("user", res.data);
        this.setState({
          user: res.data,
        });
      }
    });
  }

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header3-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header3-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </a>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header3-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: "from" }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "100vw",
            background: "#5f5f5f",
          }}
        >
          <TweenOne
            style={{ lineHeight: "0px" }}
            animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
            {...dataSource.logo}
          >
            <Link
              to="/"
              style={{
                color: "white",
                fontWeight: "bold",
                padding: 10,
                width: 50,
              }}
            >
              <img width="100%" src={Logo} alt="img" />
              {/* LOGO */}
            </Link>
          </TweenOne>
          {this.state.user ? (
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      key: "1",
                      label: (
                        <a
                          onClick={() => {
                            // localStorage.removeItem("token");
                            localStorage.clear();
                            console.log(123);
                            window.location.href =
                              "https://www.fanqiushuhua.com/#/";
                            // window.location.reload();
                          }}
                        >
                          退出
                        </a>
                      ),
                    },
                  ]}
                ></Menu>
              }
            >
              <div onClick={(e) => e.preventDefault()}>
                <Space
                  style={{
                    color: "white",
                  }}
                >
                  {/* <img src={i.avatar ? i.avatar: avatarPng} /> */}
                  <Avatar size={34} icon={<UserOutlined />} />
                  {this.state.user.name}
                  <DownOutlined />
                </Space>
              </div>
            </Dropdown>
          ) : (
            <Link to="/login" style={{ cursor: "pointer", color: "white" }}>
              登录
            </Link>
          )}

          {/* {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    x: 0,
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = "auto";
                      }
                    },
                    ease: "easeInOutQuad",
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? "inline" : "horizontal"}
              defaultSelectedKeys={["sub0"]}
              theme="light"
            >
              {navChildren}
            </Menu>
          </TweenOne> */}
        </div>
      </TweenOne>
    );
  }
}

export default Header3;
