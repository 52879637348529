import React, { useState, useEffect } from "react";
import "./less/index.less";
import { Button, Form, Input, Row, Col, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import RightPng from "../assets/right-logo.png";
import LeftPng from "../assets/left-login.png";
import serviceAxios from "../config/axios";
import { useHistory } from "react-router-dom";
const LoginPage = () => {
  const history = useHistory();
  const onFinish = (v) => {
    const { phone, code } = v;
    serviceAxios
      .post("/api/user/user/accountLogin", { phone, code })
      .then((res) => {
        if (res && res.code === 200) {
          localStorage.setItem("token", res.data.token);
          setTimeout(() => {
            // window.location.href = "/";
            history.push("/");
          }, 100);
        } else {
          message.warn(res.msg);
        }
      });
  };
  const formRef = React.createRef();

  const [messageText, setMessage] = useState("验证码");

  const send = () => {
    const phone = formRef.current.getFieldValue("phone");

    if (messageText === "验证码") {
      if (phone) {
        serviceAxios.get(`/api/sms/sendSms?phone=${phone}`).then((res) => {
          if (res && res.code === 200) {
            message.success("发送成功");
            setMessage("已发送");
          } else {
            message.warning(`${res.msg},请输入正确的手机号`);
            setMessage("验证码");
          }
        });
      } else {
        message.warning("请输入手机号");
      }
    }
  };

  useEffect(() => {
    var obj = new window.WxLogin({
      self_redirect: false,
      id: "login_container",
      appid: "wxf301e96d257c51ef",
      scope: "snsapi_login",
      redirect_uri: "https://www.fanqiushuhua.com/api/user/user/webLogin",
      // redirect_uri: "https%3A%2F%2Fapi.fanqiushuhua.com%2Fapi%2Fuser%2Fuser%2FwebLogin",
      // redirect_uri: "http%3A%2F%2Fwww.fanqiushuhua.com%2F%23%2F",
      state: "STATE",
      style: "black",
      href: "",
    });
  }, []);
  return (
    <div className="login-page" style={{}}>
      <div
        className="box"
        style={{
          // width: "80vw",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img src={LeftPng} alt="" style={{ width: "30vw" , maxWidth:400}} />
        <div
          style={{
            display: "flex",
            height: 518,
            borderRadius: 30,
          }}
        >
          <div
            id="login_container"
            style={{
              display: "flex",
              width: "50%",
              alignItems: "center",
              marginLeft: '4rem',
              background: "white",
              justifyContent: "center",
              borderTopLeftRadius: 30,
              borderBottomLeftRadius: 30,
            }}
          ></div>
          <div className="login-form">
            <img src={RightPng} alt="" className="logo" />
            <Form
              ref={formRef}
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              style={{ width: "20vw" }}
            >
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "请输入手机号",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="手机号"
                />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "验证码!",
                  },
                ]}
              >
                <Row gutter={8} justify="space-between">
                  <Col span={18}>
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="验证码"
                    />
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a onClick={send}>{messageText}</a>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    background: "#f4ba20",
                    border: "none",
                  }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  登录
                </Button>
                {/* <a
                href="https://open.weixin.qq.com/connect/qrconnect?appid=wxf301e96d257c51ef&scope=snsapi_login&redirect_uri=http%3A%2F%2Fwww.fanqiushuhua.com%2F%23%2F&state=STATE&login_type=jssdk&self_redirect=true&styletype=&sizetype=&bgcolor=&rst=&style=black"
                style={{ cursor: "pointer", color: "black" }}
              >
                <Button
                  style={{
                    width: "100%",
                    border: "none",
                    marginTop: 16,
                  }}
                  size="large"
                  type="primary"
                  className="login-form-button"
                >
                  微信登录
                </Button>
              </a> */}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
